/* THEME */
@import '~antd/dist/antd';
/* END OF THEME */

/* TEMPLATE OVERRIDES*/
body {
  margin: 0;
  font-family: 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* OVERRIDES AND ADD-ONS */
.shadow-1 {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-2 {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-3 {
  box-shadow: 0 0.2rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-4 {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.w-100 .ant-form-item-control-wrapper {
  width: 100% !important;
}

.w-90 .ant-form-item-control-wrapper {
  width: 90% !important;
}

.ant-cascader-menu {
  height: 350px !important;
}

.ant-table-small tr th,
.ant-table-small tr td {
  padding: 2px !important;
  font-size: 12px !important;
}
.ant-table-middle tr th,
.ant-table-middle tr td {
  font-size: 12.6px !important;
  padding: 4px !important;
}
.ant-table-default tr th,
.ant-table-default tr td {
  font-size: 14px !important;
  padding: 6px !important;
}

.table th,
.table td {
  padding: 2px 4px;
  vertical-align: middle !important;
  border: 1px solid #e3ebf3;
}

.table.table-striped tr:nth-child(2n) td {
  background-color: #eee;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Arial' !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.ant-form-item-explain-error {
  color: #e7532e !important;
}

.ant-btn-primary {
  border-color: #142438 !important;
  background: #142438 !important;
}

.ant-btn-link {
  color: #14243870 !important;
}

/* END OVERRIDES AND ADD-ONS */

/* ADDITIONAL HELPERS */
.pointer {
  cursor: pointer !important;
}

.w-auto {
  width: auto !important;
}

.p-y-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.p-x-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.f-s-20 {
  font-size: 20px !important;
}

.f-s-8 {
  font-size: 8px !important;
}

.f-s-10 {
  font-size: 10px !important;
}

.w-100 {
  width: 100% !important;
}

.w-85 {
  width: 85% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.border-0 {
  border: 0 !important;
}
.border-1 {
  border: 1px solid #d9d9d9 !important;
}

/* END OF ADDITIONAL HELPERS */

.fullscreen-loader {
  height: 80vh;
}

.app-layout {
  min-height: 100vh;

  .logo {
    color: white;
  }

  .top-nav {
    position: fixed;
    z-index: 1000;
    width: 100%;
    background: white;
    transition: 300ms ease all;
    border-bottom: 1px solid #ececec;
  }

  .ant-menu.ant-menu-dark.no-bck-tab-color .ant-menu-item-selected {
    background-color: transparent !important;
  }
}

.qbo-style {
  .ant-table-thead > tr > th {
    border-top: 1px solid #737373 !important;
    border-bottom: 1px solid #737373 !important;
    background: #ffffff !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #ffffff;
  }
  .ant-table-tbody > tr:last-child > td {
    border-bottom: 1px solid #f0f0f0 !important;
  }
}

.action-form {
  padding: 3rem;
}

.top-form-image {
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}

.footer-text {
  color: #14243870;
  position: fixed;
  bottom: 10px;
}
